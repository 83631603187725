import { Inject, Injectable, Optional } from '@angular/core';
import {
  ISbRichtext,
  ISbStory,
  ISbStoryParams,
  StoryblokClient,
  apiPlugin,
  renderRichText,
  storyblokInit,
} from '@storyblok/js';
import { ISbComponentType, ISbStoryData } from 'storyblok-js-client';
import {
  HOST_URL,
  STORYBLOK_MODE,
  STORYBLOK_SECRET,
} from '../../tokens/server.token';
import { FooterInputs } from '../components/footer/footer.component';
import { NavbarSectionInputs } from '../components/navbar-section/navbar-section.component';
import { ArticleInputs } from '../contents/article/article.component';
import { PageInputs } from '../contents/page/page.component';

export interface StoryblokAsset {
  id: number;
  alt: string;
  focus: string;
  title: string;
  source: string;
  filename: string;
  copyright: string;
  fieldtype: 'asset';
  cached_url: string;
  meta_data: object;
  is_private: boolean;
  is_external_url: boolean;
}

export interface StoryblokMetaTags {
  description: string;
  title: string;
  og_image: string;
}

export interface StoryblokLink {
  id: string;
  url: string;
  linktype: string;
  fieldtype: 'multilink';
  cached_url: string;
  anchor: string;
}

export interface StoryblokRichtext {
  content?: ISbRichtext[];
  marks?: ISbRichtext[];
  text?: string;
  type: string;
}

export interface StoryblokConfig {
  footer: FooterInputs[];
  navbar: NavbarSectionInputs[];
  facebook: Partial<StoryblokLink>;
  linkedin: Partial<StoryblokLink>;
  x: Partial<StoryblokLink>;
  youtube: Partial<StoryblokLink>;
  analytics: string;
  metaTags: StoryblokMetaTags;
}

export interface StoryblokArticle {
  id: number;
  uuid: string;
  created_at: string;
  updated_at: string | null;
  full_slug: string;
  published_at: string | null;
  first_published_at: string | null;
  published: boolean;
  name: string;
  slug: string;
  content: Partial<ArticleInputs>;
}

export interface StoryblokPage {
  id: number;
  uuid: string;
  created_at: string;
  updated_at: string | null;
  full_slug: string;
  published_at: string | null;
  first_published_at: string | null;
  published: boolean;
  name: string;
  slug: string;
  content: Partial<PageInputs>;
}

export interface StoryblokEditorParams {
  spaceId: string | null;
  timestamp: string | null;
  token: string | null;
}

export type StoryblokContent = StoryblokArticle | StoryblokPage;

export interface RedirectEntry extends ISbComponentType<'redirect_entry'> {
  source_slug: string;
  target_story: ISbStoryData | ILinks;
}

export interface ILinks {
  id: string;
  url: string;
  title: string;
  linktype: string;
  fieldtype: string;
  cached_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class StoryblokService {
  private secret: string = '';
  private mode: 'draft' | 'published' = 'published';
  private storyblokApi: StoryblokClient;
  private storyblokConfig: StoryblokConfig | null = null;

  constructor(
    @Optional() @Inject(STORYBLOK_SECRET) private storyblokSecret: string,
    @Optional()
    @Inject(STORYBLOK_MODE)
    private storyblokMode: 'draft' | 'published',
    @Optional() @Inject(HOST_URL) private hostUrl?: string,
  ) {
    this.secret = this.storyblokSecret || 'stub';
    this.mode = this.storyblokMode || 'published';

    const { storyblokApi } = storyblokInit({
      accessToken: this.secret,
      use: [apiPlugin],
      apiOptions: {
        region: 'us',
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      },
    });

    this.storyblokApi = storyblokApi!;
  }

  async getSitemap() {
    const prefixUrl = 'https://fhlbsf.com';
    const links = await this.storyblokApi.getAll('cdn/links', {
      version: 'published',
      per_page: 25,
    });

    const sitemap_entries = links.map((link) => {
      if (link.is_folder || link.real_path.startsWith('/configuration'))
        return '';
      return `\n    <url><loc>${prefixUrl}${link.real_path}</loc></url>`;
    });

    const sitemap = `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
    ${sitemap_entries.join('')}
    </urlset>`;

    return sitemap;
  }

  async isLiveEditor({
    spaceId,
    timestamp,
    token,
  }: StoryblokEditorParams): Promise<boolean> {
    if (this.mode === 'draft') {
      return true;
    }

    if (!spaceId || !timestamp || !token) {
      return false;
    }

    try {
      const response = await fetch(`${this.hostUrl}/api/validate-token`, {
        method: 'POST',
        body: JSON.stringify({ spaceId, timestamp, token }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const { authorized } = await response.json();

      if (authorized) {
        await this.storyblokApi.flushCache();
      }

      return authorized;
    } catch (_error) {
      return false;
    }
  }

  async getStory(slug: string, params: ISbStoryParams = {}) {
    return this.storyblokApi.getStory(slug, {
      version: this.mode,
      ...params,
      resolve_relations: [
        'featureNewsSections.articles',
        'featureBlogSections.articles',
        'updateSection.articles',
        'featureArticle.articles',
        'heroHeaderLandingSection.articles',
        'navbarArticleCardSection.articles',
      ],
    });
  }

  getStories(params: ISbStoryParams) {
    return this.storyblokApi.getStories(params);
  }

  async getContent(params: ISbStoryParams = {}): Promise<StoryblokContent[]> {
    const [pages, articles] = await Promise.all([
      this.get('page', params),
      this.getArticles(params),
    ]);

    return [
      ...(pages.data.stories as StoryblokPage[]),
      ...(articles.data.stories as StoryblokArticle[]),
    ];
  }

  async getLatestArticles(category: string) {
    const response = await this.storyblokApi.getStories({
      content_type: 'article',
      sort_by: 'first_published_at:desc',
      per_page: 3,
      page: 0,
      filter_query: { category: { in: category } },
    });

    return response.data.stories;
  }

  async get(contentType: string, params: ISbStoryParams = {}) {
    const response = await this.storyblokApi.getStories({
      content_type: contentType,
      version: this.mode,
      sort_by: 'first_published_at:desc',
      ...params,
      per_page: 100,
      page: 0,
    });

    const pages = Math.ceil(response.total / 100);
    if (pages > 1) {
      for (let page = 1; page <= pages; page++) {
        const pageResponse = await this.storyblokApi.getStories({
          content_type: contentType,
          version: this.mode,
          sort_by: 'first_published_at:desc',
          ...params,
          per_page: 100,
          page,
        });

        // add only unique stories to the response to eliminate duplicates
        response.data.stories = [
          ...response.data.stories,
          ...pageResponse.data.stories.filter(
            (story) =>
              !response.data.stories.some(
                (existingStory) => existingStory.id === story.id,
              ),
          ),
        ];
      }
    }

    return response;
  }

  async getArticles(params: ISbStoryParams = {}) {
    return this.get('article', params);
  }

  async getArticleFooter(category: string) {
    return this.storyblokApi.get('cdn/stories/', {
      starts_with: 'configuration/',
      version: this.mode,
      filter_query: { category: { in: category } },
    });
  }

  async getRedirects(params: ISbStoryParams): Promise<Record<string, string>> {
    try {
      const { data }: ISbStory = await this.storyblokApi.get(
        'cdn/stories/configuration/redirects-v2-test',
        {
          version: this.mode,
          resolve_relations: 'redirect_entry.target_story',
          ...params,
        },
      );

      const redirects: RedirectEntry[] =
        data.story.content['redirect_entries'] ?? [];

      return redirects.reduce((acc, redirect) => {
        if ('full_slug' in redirect.target_story) {
          return {
            ...acc,
            [redirect.source_slug.toLocaleLowerCase()]:
              redirect.target_story.full_slug === 'home'
                ? '/'
                : `/${redirect.target_story.full_slug}`,
          };
        } else {
          return {
            ...acc,
            [redirect.source_slug.toLocaleLowerCase()]:
              redirect.target_story.cached_url,
          };
        }
      }, {});
    } catch (_error) {
      console.warn('no configured redirects where found');
      return {};
    }
  }

  async getConfig(): Promise<void> {
    try {
      const { data }: ISbStory = await this.storyblokApi.get(
        'cdn/stories/configuration/site-config',
        {
          version: this.mode,
          resolve_relations: ['navbarArticleCardSection.articles'],
        },
      );
      this.storyblokConfig = data.story.content as StoryblokConfig;
      this.storyblokConfig.footer[0] = {
        ...this.storyblokConfig.footer[0],
        facebookLink: this.storyblokConfig.facebook ?? {},
        twitterLink: this.storyblokConfig.x ?? {},
        linkedinLink: this.storyblokConfig.linkedin ?? {},
        youtubeLink: this.storyblokConfig.youtube ?? {},
      };
    } catch (_error) {
      console.warn('no configuration present');
    }
  }

  get config() {
    return this.storyblokConfig;
  }

  renderRichText(data?: ISbRichtext) {
    return renderRichText(data);
  }
}
